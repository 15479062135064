import { render, staticRenderFns } from "./salesteamlead-vue.vue?vue&type=template&id=997a03b6&scoped=true&"
import script from "./salesteamlead-vue.vue?vue&type=script&lang=js&"
export * from "./salesteamlead-vue.vue?vue&type=script&lang=js&"
import style0 from "./salesteamlead-vue.vue?vue&type=style&index=0&id=997a03b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "997a03b6",
  null
  
)

export default component.exports
<template>
  <div class="viewDashBoard" v-cloak>
    <div class="">
      <div
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <adminvue-projects-vue
          v-if="getActiveUserData.userRoles == 'admin'"
        ></adminvue-projects-vue>
        <salesteamleadvue-projects-vue
          v-else-if="
          (getActiveUserData.userRoles == 'salesteamlead') ||
          (getActiveUserData.userRoles == 'salesmanager') ||
          (getActiveUserData.userRoles == 'marketingperson') ||
          (getActiveUserData.userRoles == 'marketingmanager')"
        ></salesteamleadvue-projects-vue>
        <salespersonleadvue-projects-vue
          v-else-if="getActiveUserData.userRoles == 'salesperson'"
        ></salespersonleadvue-projects-vue>
        <not-found v-else></not-found>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminvue from "./components/admin-vue";
import salesteamleadvue from "./components/salesteamlead-vue";
import salespersonleadvue from "./components/salesperson-vue";
import noComponent from "../../components/noComponentFound";

export default {
  components: {
    "adminvue-projects-vue": adminvue,
    "salesteamleadvue-projects-vue": salesteamleadvue,
    "salespersonleadvue-projects-vue": salespersonleadvue,
    "not-found": noComponent
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {};
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
  },
  methods: {}
};
</script>

<template>
  <div class="create-brand" v-cloak>
    <div class="container">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <div key="1" v-if="loader" class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else key="2" class="white-row">
          <div class="convert-leadto-client-form form-area">
            <div class="col-sm-12">
              <form
                class="brand-form"
                v-on:submit.prevent="onSubmit($event)"
              >
                <div class="row">
                    <div class="col-sm-12">
                        <h4>Lead Scheduler</h4>
                    </div>
                    <div class="col-sm-12">
                        <div class="row">
                        <div class="form-group">
                            <label for="lead_shedule_time">Select Date: <span>*</span></label>
                            <date-picker
                                v-model="formData.lead_shedule_time"
                                type="datetime"
                                placeholder="Select datetime"
                                
                                >
                                <template v-slot:footer>
                                    <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
                                    {{ showTimePanel ? 'select date' : 'select time' }}
                                    </button>
                                </template>
                                </date-picker>
                        </div>
                        </div>
                    </div>

                    <div class="col-sm-12">
                        <div class="row">
                            <div class="form-group">
                                <label for="am_id" class="control-label"
                                >Account Manger</label
                                >
                                <Select2
                                :options="ams_options"
                                :settings="{
                                    placeholder: 'Accoundt Manager',
                                    multiple: true
                                }"
                                v-model="formData.am_id"
                                
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="row">
                        <div class="form-actions">
                            <button
                            :disabled="disableSubmitBtn"
                            type="submit"
                            id="save-form"
                            class="btn btn-primary default-global-btn"
                            >
                            Create Scheduler
                            </button>
                            <div
                            v-if="disableSubmitBtn"
                            class="form_submit_loader"
                            >
                            <div class="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
              </form>
            </div>
            <transition
                name="fade"
                mode="out-in"
                @beforeLeave="beforeLeave"
                @enter="enter"
                @afterEnter="afterEnter"
              >
                <div key="1" v-if="data_loader" class="white-row loader-route">
                  <div class="lds-sub-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div v-else class="brand-wrapper">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Lead Title</th>
                              <th>Scheduler Time</th>
                              <th>AM Name</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody
                            v-if="
                              typeof lead_schedule_data.data !== 'undefined' &&
                                Object.keys(lead_schedule_data.data).length
                            "
                          >
                            <tr
                              v-for="(lead_schedule, lead_schedule_index) in lead_schedule_data.data"
                              :key="lead_schedule_index"
                              :id="'lead_schedule_id_'+lead_schedule.id"
                            >
                              <td>
                                {{ lead_schedule.id }}
                              </td>
                              <td>
                                {{ lead_schedule.lead_title }}
                              </td>
                              <td>
                                {{ lead_schedule.lead_schedule_time }}
                              </td>
                              <td>
                                {{ lead_schedule.scheduler_status }}
                              </td>
                              <td>
                                <p v-for="(am_name , am_index) in lead_schedule.am_list"
                                  :key="am_index"
                                >
                                  {{ am_name.name }}
                                </p>
                              </td>
                              <td>
                                ---
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="13">
                                <no-record-found></no-record-found>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
            </transition>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

// import { validationMixin } from "vuelidate";
// const {
//   required,
//   minLength,
//   email,
//   numeric,
//   url
// } = require("vuelidate/lib/validators");

import Select2 from "v-select2-component";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import norecordfound from "../../../../components/no-record-found";

export default {
  // mixins: [validationMixin],
  name : "shedule-a-call-with-client",
  components: {
    "no-record-found": norecordfound,
    Select2,
    DatePicker
  },
  // validations: {
  //   formData: {
  //     am_id: {
  //       required
  //     },
  //     lead_shedule_time : {
  //       required
  //     }
  //   }
  // },
  watch: {
    lead_sheduler_prop_lead_id: {
      handler(val){
        if(val) {
          this.loader = false;
          this.getILeadScheduleData();
        }
      },
      deep: true
    }
  },
  props: ['lead_sheduler_prop_lead_id'],
  data() {
    return {
      showTimePanel: false,
      loader: true,
      data_loader: true,
      formData: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        am_id: null,
        lead_shedule_time : null,
        lead_id : null,
      },
      lead_schedule_data: {},
      ams_options : null,
      disableSubmitBtn : false,
    };
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  mounted() {
    this.loader = false;
    this.getILeadScheduleData();
    this.gerAmLinst();
  },
  methods: {
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    async gerAmLinst() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-employees"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.ams_options = response.data.data;
        }
      });
    },
    refreshBrandTable() {
      this.getILeadScheduleData();
    },

    async getILeadScheduleData() {
      const $this = this;
      $this.formData.lead_id = this.lead_sheduler_prop_lead_id;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "leads/get-lead-schedules/" +
          this.lead_sheduler_prop_lead_id + 
          "?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_schedule_data = response.data;
          $this.data_loader = false;
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    async onSubmit(event,lead_index) {
      
      
      const $this = this;
      $this.disableSubmitBtn = true;
      // $this.$v.$touch();

      // if ($this.$v.$invalid) {
      //   $this.$store.dispatch("showtostrMessageNotifications", {
      //     message: "Please fill all the fields to proceed form!",
      //     type: "error"
      //   });
      //   $this.disableSubmitBtn = false;
      //   return;
      // }

      const data = $this.$store.dispatch("postHttp", {
        url: "leads/schedule-a-call",
        formDetails: this.formData,
        formEvent: event.target
      });
      await data.then( (response) => {
        if (typeof response !== "undefined") {
          // console.log(response.data[0]);
          $this.$store.dispatch("showtostrMessageNotifications", {
            message: response.data.message,
            type: 'success'
          });
            this.formData.lead_shedule_time = null;
            this.formData.am_id = null;
            this.getILeadScheduleData();
        }
      });
      $this.disableSubmitBtn = false;
    },
  }
};
</script>
<style lang="scss" scoped>
.brand-wrapper {
    padding: 22px 14px 0;
}
body .white-row {
    min-height: 80vh;
}
.mx-datepicker {
    width: 100%;
}
.mx-datepicker input.mx-input {
    box-shadow: none;
    border:none;
}

</style>
<template>
  <div class="viewDashBoard project-route-index lead-crm-page">
    <div class="">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <div key="1" v-if="loader" class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else key="2" class="white-row">
          <div class="show-project-portal-listing">
            <!-- // Filter Area -->
            <div class="general-filters">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="row top-gen-heading">
                      <h1 class="global-style-heading">
                        leads
                        <a
                          @click.prevent="refreshBrandTable"
                          href=""
                          class="refreshData"
                          ><i class="fas fa-redo-alt"></i
                        ></a>
                      </h1>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div class="row">
                            <div class="form-actions inline-btn">
                              <router-link
                                class="btn btn-primary"
                                type="submit"
                                id="save-form"
                                :to="{
                                  name: 'leads.create'
                                }"
                                tag="button"
                              >
                                <i class="fas fa-plus-circle"></i> Add New Lead
                              </router-link>
                              <button
                                @click="generateReport"
                                class="btn btn-primary"
                              >
                                Download CSV
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <form @submit.prevent="getILeadData">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group custom-date-picker">
                              <label for="from_date">&nbsp;</label>
                              <datepicker
                                :minimumView="'day'"
                                :maximumView="'month'"
                                :initialView="'month'"
                                :format="customFormatterfromdate"
                                placeholder="From"
                                :input-class="'form-control'"
                                :clear-button="true"
                                :value="formData.from_date"
                                @cleared="onClearDateFromDate"
                              >
                                <span
                                  slot="afterDateInput"
                                  class="animated-placeholder"
                                >
                                  <i class="far fa-calendar-alt"></i>
                                </span>
                              </datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group custom-date-picker">
                              <label for="to_date">&nbsp;</label>
                              <datepicker
                                :minimumView="'day'"
                                :maximumView="'month'"
                                :initialView="'month'"
                                :format="customFormattertodate"
                                placeholder="To"
                                :input-class="'form-control'"
                                :clear-button="true"
                                :value="formData.to_date"
                                @cleared="onClearDateToDate"
                              >
                                <span
                                  slot="afterDateInput"
                                  class="animated-placeholder"
                                >
                                  <i class="far fa-calendar-alt"></i>
                                </span>
                              </datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group full-width-md">
                              <label for="gender" class="control-label"
                                >&nbsp;</label
                              >
                              <div class="input-group">
                                <input
                                  @keyup="getILeadData"
                                  v-model="formData.search"
                                  type="text"
                                  class="form-control"
                                  placeholder="Search..."
                                />
                                <div class="input-group-append">
                                  <button
                                    class="btn btn-secondary"
                                    type="button"
                                  >
                                    <i class="fa fa-search"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group">
                              <label for="brand_id" class="control-label"
                                >&nbsp;</label
                              >
                              <Select2
                                v-model="formData.brand_id"
                                :options="project_brand_options"
                                :settings="{
                                  placeholder: 'Project Brands',
                                  allowClear: true
                                }"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group">
                              <label for="brand_id" class="control-label"
                                >&nbsp;</label
                              >
                              <Select2
                                v-model="formData.emp_id"
                                :options="project_employee_options"
                                :settings="{
                                  placeholder: 'Team Lead / Sales Person',
                                  allowClear: true
                                }"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group full-width-md">
                              <label for="brand_id" class="control-label"
                                >&nbsp;</label
                              >
                              <Select2
                                v-model="formData.lead_status_id"
                                :options="lead_status_option"
                                :settings="{
                                  placeholder: 'Lead Status',
                                  allowClear: true
                                }"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group">
                              <label for="brand_id" class="control-label"
                                >&nbsp;</label
                              >
                              <Select2
                                v-model="formData.lead_step_id"
                                :options="lead_step_option"
                                :settings="{
                                  placeholder: 'Lead Step',
                                  allowClear: true
                                }"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group">
                              <label for="brand_id" class="control-label"
                                >&nbsp;</label
                              >
                              <Select2
                                v-model="formData.lead_source_id"
                                :options="lead_source_option"
                                :settings="{
                                  placeholder: 'Lead Source',
                                  allowClear: true
                                }"
                              />
                            </div>
                          </div>
                        </div>

                         <div class="col-sm-4">
                          <div class="row">
                            <div class="form-group full-width-md">
                              <label for="brand_id" class="control-label"
                                >&nbsp;</label
                              >
                              <Select2
                                v-model="formData.lead_source_id_remove_tag"
                                :options="lead_source_option"
                                :settings="{
                                  placeholder: 'Lead Source Not Having this Tag',
                                  allowClear: true
                                }"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="row">
                            <div  class="form-actions">
                              <button type="submit" class="btn btn-primary default-global-btn"> Search </button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="horizontal-row">
              <hr />
              <div class="paginate-select-box general-filters">
                <form @submit.prevent="">
                    <div class="form-group">
                      <label for="brand_id" class="control-label"
                        >&nbsp;</label
                      >
                      <Select2
                        @change="getILeadData(1)"
                        v-model="formData.number_per_page"
                        :options="page_numbers"
                        :settings="{
                          placeholder: 'Select Lead Per page',
                        }"
                      />
                    </div>
                </form>
              </div>
            </div>
            <div class="filter-box-area">
              <div class="row">
                <div class="col-sm-3">
                  <div  class="box-dash">
                    <span  class="icon-Path-5637"></span>
                    <p  class="txt">Total Leads</p>
                    <p  class="numb"> {{ lead_box_data.total_leads }} </p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div  class="box-dash">
                    <span  class="fas fa-trophy"></span>
                    <p  class="txt">Won</p>
                    <p  class="numb"> {{ lead_box_data.total_won_leads }} </p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div  class="box-dash">
                    <span  class="far fa-frown"></span>
                    <p  class="txt">Lost</p>
                    <p  class="numb"> {{ lead_box_data.total_lost_leads }} </p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div  class="box-dash">
                    <span  class="fas fa-exclamation-circle"></span>
                    <p  class="txt">To Do</p>
                    <p  class="numb"> {{ lead_box_data.total_todo_leads }} </p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div  class="box-dash">
                    <span  class="fas fa-exclamation-circle"></span>
                    <p  class="txt">Stand By</p>
                    <p  class="numb"> {{ lead_box_data.total_standby_leads }} </p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div  class="box-dash">
                    <span  class="fas fa-exclamation-circle"></span>
                    <p  class="txt">Cancelled</p>
                    <p  class="numb"> {{ lead_box_data.total_cencelled_leads }} </p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div  class="box-dash">
                    <span  class="fas fa-trophy"></span>
                    <p  class="txt">Won ratio</p>
                    <p  class="numb"> {{ lead_box_data.total_won_closing_leads }} </p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div  class="box-dash">
                    <span  class="far fa-frown"></span>
                    <p  class="txt">Lost ratio</p>
                    <p  class="numb"> {{ lead_box_data.total_lostclosing_leads }} </p>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div  class="box-dash">
                    <span  class="fas fa-exclamation-circle"></span>
                    <p  class="txt">Cancelled ratio</p>
                    <p  class="numb"> {{ lead_box_data.total_cencelledclosing_leads }} </p>
                  </div>
                </div>
              </div>
            </div>
            <section class="brands">
              <transition
                name="fade"
                mode="out-in"
                @beforeLeave="beforeLeave"
                @enter="enter"
                @afterEnter="afterEnter"
              >
                <div key="1" v-if="data_loader" class="white-row loader-route">
                  <div class="lds-sub-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div v-else class="brand-wrapper">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Action</th>
                          <th>lead's Info</th>
                          <th>Status</th>
                          <th>Step</th>
                          <th>Source</th>
                          <th>Brand</th>
                          <th>Sales Person</th>
                          <th>Amount</th>
                          <th>Created At</th>
                          <th>last Updated</th>
                        </tr>
                      </thead>
                      <tbody
                        v-if="
                          typeof lead_data.data !== 'undefined' &&
                            Object.keys(lead_data.data).length
                        "
                      >
                        <tr
                          v-for="(lead, lead_index) in lead_data.data"
                          :key="lead_index"
                          :class="[lead.leadstatus.color_code , (lead.converted_user_id != null) ? 'client-converted-cls' : '']"
                          :id="'lead_id_'+lead.id"
                        >
                          <td>
                            {{ lead.id }}
                          </td>
                          <td>
                            <router-link
                              class="invoice-icon"
                              title="Edit!"
                              data-placement="top"
                              :to="{ 
                                path: '/leads/edit/'+lead.id + '?current_page='+current_page_number + 
                                '&search=' +
                                formData.search +
                                '&brand_id=' +
                                formData.brand_id +
                                '&emp_id=' +
                                formData.emp_id +
                                '&lead_status_id=' +
                                formData.lead_status_id +
                                '&lead_step_id=' +
                                formData.lead_step_id +
                                '&lead_source_id=' +
                                formData.lead_source_id +
                                '&lead_source_id_remove_tag=' +
                                formData.lead_source_id_remove_tag +
                                '&from_date=' +
                                formData.from_date +
                                '&number_per_page=' +
                                formData.number_per_page +
                                '&to_date=' +
                                formData.to_date
                              }"
                              tag="a"
                            >
                              <p class="invoice-icon">
                                <i class="far fa-edit"></i>
                              </p>
                            </router-link>
                            <a
                              v-if="getActiveUserData.user.id == 1755"
                              data-toggle="tooltip"
                              title="Delete!"
                              class="invoice-icon"
                              data-placement="top"
                              href="#"
                              v-confirm="{
                                loader: true,
                                ok: dialog => deletLead(dialog, lead_index),
                                cancel: doNothing,
                                message: 'Are you sure? You want to delete it.'
                              }"
                            >
                              <p class="invoice-icon">
                                <i class="fas fa-times"></i>
                              </p>
                            </a>
                          </td>
                          <td>
                              <p>
                                <b>Lead Name :</b> <a class="invoice-title global-para-link2" @click="showTermsCondition(lead_index)" href="#">{{ lead.name }}</a>
                              </p>
                              <p>
                                <b> Lead Email :</b> 
                                {{ (lead.email) ? lead.email : 'N/A' }}
                              </p>
                              <p>
                                <b> Lead Contact :</b> 
                                {{ (lead.contact) ? lead.contact : 'N/A' }}
                              </p>
                              <a @click="showTermsCondition(lead_index)" href="#">View Details</a>
                              <br>
                              <a v-if="lead.converted_user_id == null" @click="convertLeadToClient(lead_index)" href="#">Convert Lead To Client</a> <br>
                              <a @click="SheduleACall(lead_index)" href="#">Shedule a call</a>
                          </td>
                          <td>
                            <div class="status-dropbox" 
                              v-if="
                                typeof lead.leadstatus !== 'undefined' &&
                                  Object.keys(lead.leadstatus).length
                              ">
                                  <div class="dropdown show">
                                      <a class="btn btn-danger btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <template v-if="lead.leadstatus.icon_class">
                                              <span v-if="lead.icon_status">
                                                <i :class="lead.leadstatus.icon_class"></i>
                                              </span>
                                              <div
                                                v-else
                                                class="form_submit_loader"
                                              >
                                                <div class="lds-ellipsis">
                                                  <div></div>
                                                  <div></div>
                                                  <div></div>
                                                  <div></div>
                                                </div>
                                              </div>
                                          </template>
                                          <template v-else>
                                            {{ lead.leadstatus.name }}
                                          </template>
                                      </a>

                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                          <a v-for="(status , status_index) in lead_status.data" :key="status_index" @click.prevent="changeStatus(status_index,lead_index)"  class="dropdown-item" :class="(lead.leadstatus.id == status.id ? 'active' : '' )" href="#">
                                            <i :class="status.icon_class"></i> {{ status.name }}
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </td>
                          <td>
                            <div class="steps-dropbox"
                              v-if="
                              typeof lead.leadstep !== 'undefined' &&
                                Object.keys(lead.leadstep).length
                            ">
                                <div class="dropdown show">
                                    <a class="btn btn-danger btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{ lead.leadstep.name }}
                                    </a>

                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a v-for="(steps , steps_index) in lead_steps.data" :key="steps_index" @click.prevent="changeSteps(steps_index,lead_index)"  class="dropdown-item" :class="(lead.leadstep.id == steps.id ? 'active' : '' )" href="#">
                                          {{ steps.name }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                          </td>
                          <td>
                            <div class="badges-area" 
                            v-if="
                              typeof lead.lead_source !== 'undefined' &&
                                Object.keys(lead.lead_source).length
                            ">
                              <span v-for="(source , source_index) in lead.lead_source" :key="source_index" class="badge badge-dark">{{ source.name }}</span>
                            </div>
                          </td>
                          <td>
                            {{ lead.brand }}
                          </td>
                          <td>
                            <div class="badges-area" 
                            v-if="
                              typeof lead.account_manager !== 'undefined' &&
                                Object.keys(lead.account_manager).length
                            ">
                              <span v-for="(salespeson , salespeson_index) in lead.account_manager" :key="salespeson_index" class="badge badge-dark">{{ salespeson.name }}</span>
                            </div>
                          </td>
                          <td>
                            ${{ lead.amount | numFormat }}
                          </td>
                          <td>
                            {{ lead.created }}
                          </td><td>
                            {{ lead.updated }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="13">
                            <no-record-found></no-record-found>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <pagination
                        class="pagination justify-content-center"
                        :data="lead_data"
                        :limit="1"
                        @pagination-change-page="getILeadData"
                      ></pagination>
                    </div>
                  </div>
                  <modal
                  name="terms-and-condition-modal"
                  height="auto"
                  width="60%"
                  :scrollable="true"
                >
                  <div class="ModalLoader">
                    <div class="prevNext">
                      <a class="prev btn btn-primary default-global-btn" v-if="prop_lead_index != 0" @click.prevent="goPrev" href="#">Prev</a>
                      <a class="next btn btn-primary default-global-btn" v-if="prop_lead_index != (lead_data.data.length - 1)" @click.prevent="goNext" href="#">Next</a>
                    </div>
                    <show-lead-details :prop_lead_id="prop_lead_id" @updateLeadStatus="updateLeadStatus($event)" @updateLeadIcon="updateLeadIcon($event)"></show-lead-details>
                  </div>
                </modal>
                <modal
                    name="convert-lead-to-client"
                    height="auto"
                    width="60%"
                    :scrollable="true"
                  >
                    <div class="ModalLoader">
                      <lead-to-client-convert :prop_lead_id="prop_lead_id" @updateLeadData="updateLeadData($event)"></lead-to-client-convert>
                    </div>
                </modal>
                <modal
                    name="shedule-a-call-with-client"
                    height="auto"
                    width="60%"
                    :scrollable="true"
                  >
                    <div class="ModalLoader">
                      <shedule-a-call-with-client :lead_sheduler_prop_lead_id="lead_sheduler_prop_lead_id"></shedule-a-call-with-client>
                    </div>
                </modal>
                </div>
              </transition>
            </section>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Datepicker from "vuejs-datepicker";
// import Avatar from "vue-avatar";
import norecordfound from "../../../components/no-record-found";
import Select2 from "v-select2-component";
import detailVueLead from "./sub-childrens/sub-admin-vue";
import leadtoclient from "./sub-childrens/convert-lead-to-client";
import sheduleacallwithclient from "./sub-childrens/shedule-a-call-with-client";
export default {
  components: {
    // Avatar,
    "no-record-found": norecordfound,
    Select2,
    Datepicker,
    "show-lead-details" : detailVueLead,
    "lead-to-client-convert" : leadtoclient,
    "shedule-a-call-with-client" : sheduleacallwithclient,
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {
      loader: true,
      data_loader: true,
      formData: {
        search: "",
        brand_id: null,
        emp_id: null,
        lead_status_id: null,
        lead_step_id: null,
        lead_source_id: null,
        lead_source_id_remove_tag: null,
        number_per_page: 20,
        from_date : null,
        to_date : null,
      },
      lead_data: {},
      lead_status : {},
      lead_steps : {},
      project_brand_options : null,
      project_employee_options: null,
      lead_status_option : null,
      lead_step_option : null,
      lead_source_option : null,
      // Prop Value pass to modal
      prop_lead_id : null,
      prop_lead_index : null,
      converted_prop_lead_index : null,
      page_numbers : [
        {
          id : 20,
          text : 20
        },
        {
          id : 100,
          text : 100
        },
        {
          id : 300,
          text : 300
        },
        {
          id : 500,
          text : 500
        },
      ],
      lead_box_data : {},
      // comback url structure
      current_page_number : null,
      // Lead Sheduler
      lead_sheduler_index : null,
      lead_sheduler_prop_lead_id : null,
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.loader = false;
    this.getprojectbrands();
    this.getLeadStatusFilter();
    this.getLeadStepFilter();
    this.getLeadSourceFilter();
    this.getemployees();
    this.getILeadData();
    this.getILeadStatus();
    this.getILeadSteps();
  },
  methods: {
    async SheduleACall(lead_index) {
      this.lead_sheduler_index = lead_index;
      this.lead_sheduler_prop_lead_id = this.lead_data.data[this.lead_sheduler_index].id;
      this.$modal.show("shedule-a-call-with-client");
      // alert("hi");
    },
    async generateReport(inv_id = null) {
      const $this = this;
      // $this.$store.dispatch("generalLoader", {
      //   response: true
      // });
      await window
        .axios({
          url:
            "leads/generate-report" 
            +
            "?search=" +
            this.formData.search +
            "&brand_id=" +
            this.formData.brand_id +
            "&emp_id=" +
            this.formData.emp_id +
            "&lead_status_id=" +
            this.formData.lead_status_id +
            "&lead_step_id=" +
            this.formData.lead_step_id +
            "&lead_source_id=" +
            this.formData.lead_source_id +
            "&lead_source_id_remove_tag=" +
            this.formData.lead_source_id_remove_tag +
            "&from_date=" +
            this.formData.from_date +

            "&number_per_page=" +
            this.formData.number_per_page +

            "&to_date=" +
            this.formData.to_date +

            "&timezone=" +
            Intl.DateTimeFormat().resolvedOptions().timeZone,
          method: "GET",
          responseType: "blob" // important
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "leads.csv"
          );
          document.body.appendChild(link);
          link.click();
          // $this.$store.dispatch("generalLoader", {
          //   response: false
          // });
        });
    },
    goNext() {
      this.prop_lead_index = this.prop_lead_index + 1;
      this.prop_lead_id = this.lead_data.data[this.prop_lead_index].id;
    },
    goPrev() {
      this.prop_lead_index = this.prop_lead_index - 1;
      this.prop_lead_id = this.lead_data.data[this.prop_lead_index].id;
    },
    updateLeadIcon(iconStatus) {
      this.$set(this.lead_data.data[this.prop_lead_index], 'icon_status', iconStatus);
    },
    updateLeadStatus(leadData) {
      this.$set(this.lead_data.data[this.prop_lead_index], 'leadstatus', leadData.leadstatus);
      this.$set(this.lead_data.data[this.prop_lead_index], 'leadstep', leadData.leadstep);
    },
    showTermsCondition(lead_index) {
      this.prop_lead_index = lead_index;
      this.prop_lead_id = this.lead_data.data[this.prop_lead_index].id;
      this.$modal.show("terms-and-condition-modal");
    },
    hideTermsCondition() {
      this.$modal.hide("terms-and-condition-modal");
    },
    convertLeadToClient(lead_index) {
      this.converted_prop_lead_index = lead_index;
      this.prop_lead_id = this.lead_data.data[this.converted_prop_lead_index].id;
      this.$modal.show("convert-lead-to-client");
    },
    updateLeadData(leadData) {
      this.$set(this.lead_data.data, this.converted_prop_lead_index, leadData.data);
      this.$modal.hide("convert-lead-to-client");
      // this.$set(this.lead_data.data[this.prop_lead_index], 'leadstep', leadData.leadstep);
    },
    onClearDateFromDate() {
      this.formData.from_date = null;
    },
    onClearDateToDate() {
      this.formData.to_date = null;
    },
    customFormatterfromdate(val) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var new_val = months[val.getMonth()] +
            " " +
            val.getDate() +
            ", " +
            val.getFullYear();
      this.formData.from_date = new_val;
      return new_val;
    },
    customFormattertodate(val) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var new_val = months[val.getMonth()] +
            " " +
            val.getDate() +
            ", " +
            val.getFullYear();
      this.formData.to_date = new_val;
      return new_val;
    },
    async getLeadSourceFilter() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-lead-source"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_source_option = response.data.data;
        }
      });
    },
    async getLeadStepFilter() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-lead-step"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_step_option = response.data.data;
        }
      });
    },
    async getLeadStatusFilter() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-lead-status"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_status_option = response.data.data;
        }
      });
    },
    async getemployees() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-employees"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.project_employee_options = response.data.data;
        }
      });
    },
    async getprojectbrands() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-project-brand"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.project_brand_options = response.data.data;
        }
      });
    },
    async changeStatus(new_index,main_index) {
      const $this = this;
      $this.$store.dispatch("generalLoader", {
        response: true
      });
      let current_id = $this.lead_status.data[new_index].id;
      let lead = $this.lead_data.data[main_index];
      if(current_id === lead.leadstatus.id) {
        $this.$store.dispatch("generalLoader", {
          response: false
        });
        return;
      }
      this.$set(this.lead_data.data[main_index], 'icon_status', false);
      const data = $this.$store.dispatch("postHttp", {
        url: "leads/change-lead-status",
        formDetails: { 
          lead_id : lead.id,
          status_id : current_id,
        },
        formEvent: null
      });
      await data.then( (response) => {
        if (typeof response !== "undefined") {
          this.$set(this.lead_data.data[main_index], 'leadstatus', $this.lead_status.data[new_index]);
          $this.$store.dispatch("displayMessageErrors", {
            response: response,
            formEvent: null,
          });
          $this.$store.dispatch("generalLoader", {
            response: false
          });
          this.$set(this.lead_data.data[main_index], 'icon_status', true);
        }
      });
    },
    async changeSteps(new_index,main_index) {
      const $this = this;
      $this.$store.dispatch("generalLoader", {
        response: true
      });
      let current_id = $this.lead_steps.data[new_index].id;
      let lead = $this.lead_data.data[main_index];
      if(current_id === lead.leadstep.id) {
        $this.$store.dispatch("generalLoader", {
          response: false
        });
        return;
      }
      const data = $this.$store.dispatch("postHttp", {
        url: "leads/change-lead-step",
        formDetails: { 
          lead_id : lead.id,
          step_id : current_id,
        },
        formEvent: null
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_data.data[main_index].leadstep.name = $this.lead_steps.data[new_index].name;
          $this.lead_data.data[main_index].leadstep.id = $this.lead_steps.data[new_index].id;
          $this.$store.dispatch("displayMessageErrors", {
            response: response,
            formEvent: null,
          });
          $this.$store.dispatch("generalLoader", {
            response: false
          });
        }
      });
    },
    refreshBrandTable() {
      this.getILeadData();
      this.getILeadStatus();
      this.getILeadSteps(); 
    },
    async getILeadSteps() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "leads/lead-steps"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_steps = response.data;
        }
      });
    },
    async getILeadStatus() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "leads/lead-status"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_status = response.data;
        }
      });
    },
    async getILeadData(page = 1, action = null) {
      const $this = this;
      $this.data_loader = true;
      if (Object.keys($this.$route.query).length) {
          if($this.$route.query.current_page) {
            this.formData.search = $this.$route.query.search;
            this.formData.brand_id = $this.$route.query.brand_id;
            this.formData.emp_id = $this.$route.query.emp_id;
            this.formData.lead_status_id = $this.$route.query.lead_status_id;
            this.formData.lead_step_id = $this.$route.query.lead_step_id;
            this.formData.lead_source_id = $this.$route.query.lead_source_id;
            this.formData.from_date = $this.$route.query.from_date;
            this.formData.number_per_page = $this.$route.query.number_per_page;
            this.formData.to_date = $this.$route.query.to_date;
            this.formData.lead_source_id_remove_tag = $this.$route.query.lead_source_id_remove_tag;
            page = $this.$route.query.current_page;
          }
      }

      if(this.formData.number_per_page == null) {
        this.formData.number_per_page = 20;
      }
      this.getILeadBoardData();
      const data = $this.$store.dispatch("getHttp", {
        url:
          "leads" +
          "?" +
          "page=" +
          page +
          "&search=" +
          this.formData.search +
          "&brand_id=" +
          this.formData.brand_id +
          "&emp_id=" +
          this.formData.emp_id +
          "&lead_status_id=" +
          this.formData.lead_status_id +
          "&lead_step_id=" +
          this.formData.lead_step_id +
          "&lead_source_id=" +
          this.formData.lead_source_id +
          "&lead_source_id_remove_tag=" +
          this.formData.lead_source_id_remove_tag +
          "&from_date=" +
          this.formData.from_date +

          "&number_per_page=" +
          this.formData.number_per_page +

          "&to_date=" +
          this.formData.to_date +

          "&timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.data_loader = false;
          $this.lead_data = response.data;
          $this.current_page_number = response.data.meta.current_page;
          if (Object.keys($this.$route.query).length) {
              let class_name = '#lead_id_'+$this.$route.query.highlited;
            setTimeout(function () { 
              $('html, body').animate({
                  scrollTop: $(class_name).offset().top
              }, 2000);
              let query = Object.assign({}, $this.$route.query);
              delete query.current_page;
              delete query.highlited;
              delete query.search;
              delete query.emp_id;
              delete query.lead_source_id_remove_tag;
              delete query.brand_id;
              delete query.lead_status_id;
              delete query.lead_step_id;
              delete query.lead_source_id;
              delete query.from_date;
              delete query.number_per_page;
              delete query.to_date;
              $this.$router.replace({ query });
            }.bind(this), 1000)
          }
        }
      });
    },
    async getILeadBoardData(page = 1, action = null) {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "leads/board-data" +
          "?" +
          "page=" +
          page +
          "&search=" +
          this.formData.search +
          "&brand_id=" +
          this.formData.brand_id +
          "&emp_id=" +
          this.formData.emp_id +
          "&lead_status_id=" +
          this.formData.lead_status_id +
          "&lead_step_id=" +
          this.formData.lead_step_id +
          "&lead_source_id=" +
          this.formData.lead_source_id +
          "&lead_source_id_remove_tag=" +
          this.formData.lead_source_id_remove_tag +
          "&from_date=" +
          this.formData.from_date +

          "&number_per_page=" +
          this.formData.number_per_page +

          "&to_date=" +
          this.formData.to_date +

          "&timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_box_data = response.data;
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    async onSubmit(event,lead_index) {
      this.$store.dispatch("generalLoader", {
        response: true
      });
      let comment = event.target.elements.comment_input.value;
      let lead_id = this.lead_data.data[lead_index].id;
      if((comment == 'null') || (comment == '')) {
        return;
      }
      const $this = this;

      const data = $this.$store.dispatch("postHttp", {
        url: "comment/store",
        formDetails: {
          'lead_id' : lead_id,
          'comment' : comment,
        },
        formEvent: null
      });
      await data.then( (response) => {
        if (typeof response !== "undefined") {
          this.$set(this.lead_data.data, lead_index, response.data.data)
          this.$store.dispatch("generalLoader", {
            response: false
          });
          $this.$store.dispatch("showtostrMessageNotifications", {
            message: 'Comment Posted Successfully!.',
            type: 'success'
          });
          event.target.elements.comment_input.value = '';
        }
      });
    },
    async deletLead(dialog, data_index) {
      const $this = this;
      let lead_id = $this.lead_data.data[data_index].id;
      const data = $this.$store.dispatch("deleteHttp", {
        url: "leads/delete/" + lead_id
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.$store.dispatch("showtostrMessageNotifications", {
            message: response.data.message,
            type: response.data.status
          });
          $this.refreshBrandTable();
          dialog.close();
        }
      });
    },
    doNothing: function() {
      // Do nothing or some other stuffs
    }
  }
};
</script>
<style lang="scss" scoped>
.client-converted-cls {
    background: #e3ece6;
}
.viewDashBoard.project-route-index.lead-crm-page {
    width: 90%;
    margin: 0 auto;
}
.prevNext {
    padding: 10px 16px;
    position: relative;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    a.next.btn.btn-primary.default-global-btn {
        float: right;
    }
}
.filter-box-area {
  .box-dash {
      position: relative;
      background: #faf9f7;
      padding: 15px 20px 15px 26px;
      margin-bottom: 20px;
      p {
        margin: 0;
        font-weight: bold;
        &.txt {
          padding-top: 6px;
          padding-bottom: 6px;
        }
        &.numb {
          font-size: 20px;
        }
      }
      span,svg {
        color: #2d9cea;
        font-weight: bold;
        font-size: 20px;
        &:before {
          color: #2d9cea;
          font-weight: bold;
        }
      }
  }
}
.horizontal-row {
    padding: 30px 0 0;
    .paginate-select-box {
      &:after {
        content:"";
        display: block;
        clear: both;
      }
      .form-group {
        width: 10% !important;
        float: right;
        padding: 10px 0 20px;
      }
    }
}
.full-width-md {
  width: 100% !important;
}
.badges-area span.badge.badge-dark {
    margin-right: 5px;
    font-size: 12px;
    padding: 3px 14px;
    color: #fff;
    background-color: #6c6c6c;
    opacity: 1;
    display: block;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
}
body .white-row.loader-route {
  top: 0;
}
span.bp-dropdown__btn.bp-dropdown__btn--active.-bp__btn--active {
    background-color: transparent;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.project-route-index {
  .show-project-portal-listing {
    .general-filters {
      .form-group {
        width: 95%;
        .input-group {
          input {
            border-radius: 3px !important;
            background: #f9f9f9;
            letter-spacing: 0.28px;
            font-size: 14px;
            padding: 3px 15px;
            color: #364460;
            border: none;
            font-weight: 200;
            height: auto;
          }
          button {
            background: #f9f9f9;
            color: #3b4c54;
            border: none;
            padding: 4px 8px;
          }
        }
      }
      .form-actions {
        width: 95%;
        button {
          width: 100%;
          margin-left: 0;
          padding: 5px 0;
        }
        &.inline-btn {
          text-align: right;
          width: 100%;
          button {
            width: auto;
            margin-left: 0;
            padding: 5px 20px;
            margin: 0 10px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    h4 {
      letter-spacing: 0.28px;
      color: #323f54;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 7px;
    }
  }
}

.table-responsive {
    table {
      margin-bottom: 0;
      thead {
        th {
          letter-spacing: 0.28px;
          color: #2a1c0b;
          font-weight: 200;
          white-space: nowrap;
        }
      }
      tbody {
        tr {
          td {
            vertical-align: middle;
            white-space: nowrap;
            a.invoice-title.global-para-link2 {
                display: inline-block;
                font-weight: bold;
                text-transform: capitalize;
            }
            p {
              margin-bottom: 0px;
              color: #323f54;
              font-weight: 300;
              letter-spacing: 0.28px;
              b {
                font-weight: bold;
              }
              &.invoice-title {
                letter-spacing: 0.28px;
                font-weight: bold;
              }
              &.invoice-total {
                font-weight: bold;
              }
              &.invoice-status {
                display: inline-block;
                background: #ece9df 0% 0% no-repeat padding-box;
                border: 1px solid #00000000;
                border-radius: 15px;
                padding: 3px 17px;
              }
              &.invoice-icon {
                display: inline-block;
                background: #1190ee;
                color: #fff !important;
                padding: 6px 10px;
                border-radius: 5px;
                margin-right: 10px;
              }
              &.invoice-status {
                font-size: 10px;
                border-radius: 10px;
                text-transform: capitalize;
                font-weight: bold !important;
                line-height: 20px !important;
                padding: 0 10px;
                text-align: center;
                display: inline-block;
                border: 1px solid #00000000;
                width: 100%;
                &.danger {
                  background: red;
                  color: #fff !important;
                }
                &.success {
                  background: rgba(0, 128, 0, 0.72);
                  color: #fff !important;
                }
              }
            }
            .status-dropbox {
              .lds-ellipsis {
                  top: -3px;
              }
              .dropdown-toggle {
                &::after {
                    content: unset;
                }
              }
              .dropdown-item {
                  padding: 7px 13px;
                  i {
                    margin-right:5px;
                  }
                  svg {
                    margin-right:5px;
                  }
              }
              .btn {
                  width: auto;
                  background: #1190EE;
                  border-color: #1190EE;
                  font-size: 14px;
                  padding: 0 15px;
              }
            }
            .steps-dropbox {
                .btn {
                    width: auto;
                    background: #1190EE;
                    border-color: #1190EE;
                    font-size: 14px;
                    padding: 0 15px;
                }
            }
          }
          &.danger {
            .status-dropbox {
              .btn {
                background: #dc3545;
                border-color: #dc3545;
              }
              .dropdown-item.active, .dropdown-item:active {
                background: #dc3545;
              }
            }
          }
          &.success {
            .status-dropbox {
              .btn {
                background: #28a745;
                border-color: #28a745;
              }
              .dropdown-item.active, .dropdown-item:active {
                background: #28a745;
              }
            }
          }
        }
      }
    }
  }
</style>

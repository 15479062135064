import { render, staticRenderFns } from "./convert-lead-to-client.vue?vue&type=template&id=5bc9a912&scoped=true&"
import script from "./convert-lead-to-client.vue?vue&type=script&lang=js&"
export * from "./convert-lead-to-client.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc9a912",
  null
  
)

export default component.exports
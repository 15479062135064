import { render, staticRenderFns } from "./shedule-a-call-with-client.vue?vue&type=template&id=6b4aafc2&scoped=true&"
import script from "./shedule-a-call-with-client.vue?vue&type=script&lang=js&"
export * from "./shedule-a-call-with-client.vue?vue&type=script&lang=js&"
import style0 from "./shedule-a-call-with-client.vue?vue&type=style&index=0&id=6b4aafc2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b4aafc2",
  null
  
)

export default component.exports
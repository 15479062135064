<template>
  <div class="create-brand" v-cloak>
    <div class="container">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <div key="1" v-if="loader" class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else key="2" class="white-row">
          <div class="convert-leadto-client-form form-area">
            <div class="col-sm-12">
                  <form
                    class="brand-form"
                    v-on:submit.prevent="onSubmit($event)"
                  >
                    <div class="row">
                        <div class="col-sm-12">
                            <h4>Client Info</h4>
                        </div>
                        <div class="col-sm-4">
                            <div class="row">
                              <div class="form-group">
                                  <label for="email"
                                  >Client Email: <span>*</span></label
                                  >
                                  <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Client Email"
                                  v-model="formData.email"
                                  :class="{
                                    'input-border-errors':
                                      $v.formData.email.$dirty &&
                                      !$v.formData.email.required
                                  }"
                                  />
                              </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="row">
                              <div class="form-group">
                                  <label for="name"
                                  >CLient Name: </label
                                  >
                                  <input
                                  type="text"
                                  class="form-control"
                                  placeholder="CLient Name"
                                  v-model="formData.name"
                                  :class="{
                                    'input-border-errors':
                                      $v.formData.name.$dirty &&
                                      !$v.formData.name.required
                                  }"
                                  />
                              </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="row">
                              <div class="form-group">
                                  <label for="contact" class="control-label"
                                  >Contact</label
                                  >
                                  <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Contact"
                                  v-model="formData.client_contact"
                                  />
                              </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <h4>Project Info</h4>
                        </div>
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="row">
                                        <div class="form-group">
                                            <label for="project_name" class="control-label"
                                            >Project Name :</label
                                            >
                                            <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Project Name"
                                            v-model="formData.project_name"
                                            :class="{
                                              'input-border-errors':
                                                $v.formData.project_name.$dirty &&
                                                !$v.formData.project_name.required
                                            }"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="row">
                                        <div class="form-group">
                                          <label for="am_id" class="control-label"
                                          >Account Manger</label
                                          >
                                          <Select2
                                          :options="ams_options"
                                          :settings="{
                                              placeholder: 'Project Status',
                                              allowClear: true
                                          }"
                                          v-model="formData.am_id"
                                          :class="{
                                              'input-border-errors':
                                                $v.formData.am_id.$dirty &&
                                                !$v.formData.am_id.required
                                            }"
                                          />
                                      </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="row">
                                      <div class="form-group">
                                          <label for="project_category_id" class="control-label"
                                          >Project Category</label
                                          >
                                          <Select2
                                          :options="project_category_options"
                                          :settings="{
                                              placeholder: 'Project Category',
                                              allowClear: true
                                          }"
                                          v-model="formData.project_category_id"
                                          :class="{
                                              'input-border-errors':
                                                $v.formData.project_category_id.$dirty &&
                                                !$v.formData.project_category_id.required
                                            }"
                                          />
                                      </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="row">
                                      <div class="form-group">
                                          <label for="project_status_id" class="control-label"
                                          >Project Status</label
                                          >
                                          <Select2
                                          :options="project_status_options"
                                          :settings="{
                                              placeholder: 'Project Status',
                                              allowClear: true
                                          }"
                                          v-model="formData.project_status_id"
                                          :class="{
                                              'input-border-errors':
                                                $v.formData.project_status_id.$dirty &&
                                                !$v.formData.project_status_id.required
                                            }"
                                          />
                                      </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                  <div class="row">
                                      <div class="form-group">
                                          <label for="project_summary" class="control-label">Package details :</label>
                                          <ckeditor
                                          v-model="formData.project_summary"
                                          :config="editorConfig"
                                          :class="{
                                              'input-border-errors':
                                                $v.formData.project_summary.$dirty &&
                                                !$v.formData.project_summary.required
                                            }"
                                          ></ckeditor>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="row">
                            <div class="form-actions">
                                <button
                                :disabled="disableSubmitBtn"
                                type="submit"
                                id="save-form"
                                class="btn btn-primary default-global-btn"
                                >
                                Convert
                                </button>
                                <div
                                v-if="disableSubmitBtn"
                                class="form_submit_loader"
                                >
                                <div class="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                  </form>
                </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import { validationMixin } from "vuelidate";
const {
  required,
  minLength,
  email,
  numeric,
  url
} = require("vuelidate/lib/validators");

import Select2 from "v-select2-component";

export default {
  mixins: [validationMixin],
  name : "conver-lead-to-client",
  components: {
    Select2
  },
  validations: {
    formData: {
      name: {
        required
      },
      email: {
        required
      },
      project_name: {
        required
      },
      project_category_id: {
        required
      },
      project_status_id: {
        required
      },
      project_summary: {
        required
      },
      am_id: {
        required
      }
    }
  },
  watch: {
    prop_lead_id: {
      handler(val){
        if(val) {
          this.loader = false;
          this.getILeadData();
        }
      },
      deep: true
    }
  },
  props: ['prop_lead_id'],
  data() {
    return {
      loader: true,
      data_loader: true,
      formData: {
        name : null,
        email: null,
        client_contact: null,
        // Project Details 
        project_name : null,
        project_category_id : null,
        project_status_id : null,
        project_summary : null,
        // Account Manager
        am_id: null,
        brand_id: null,
        lead_id : null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      lead_data: {},
      project_category_options : null,
      project_status_options : null,
      ams_options : null,
      disableSubmitBtn : false,
      editorConfig: {
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi"]
          },
          { name: "links" },
          { name: "styles" },
          { name: "colors" },
          { name: "tools" },
          { name: "others" },
          { name: "about" }
        ],
        // The configuration of the editor.
        extraPlugins: "font",
        versionCheck: false,
      },
    };
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  mounted() {
    this.loader = false;
    this.getILeadData();
    this.getProjectCategory();
    this.getProjectStatusCategory();
    console.log(this.prop_lead_id);
  },
  methods: {
    async getProjectCategory() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-project-category"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.project_category_options = response.data.data;
        }
      });
    },
    async getProjectStatusCategory() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-project-status-category"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.project_status_options = response.data.data;
        }
      });
    },
    refreshBrandTable() {
      this.getILeadData();
    },

    async getILeadData() {
      const $this = this;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "leads/get-short-details-of-lead/" +
          this.prop_lead_id + 
          "?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          console.log(response.data);
          $this.formData.name = response.data.data.title;
          $this.formData.email = response.data.data.email;
          $this.formData.client_contact = response.data.data.contact;
          $this.formData.brand_id = response.data.data.brand.id;
          $this.formData.lead_id = response.data.data.id;
          $this.getProjectAms(response.data.data.brand.id);
          $this.lead_data = response.data;
          $this.data_loader = false;
        }
      });
    },
    async getProjectAms(brandid) {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-employees?brand_id=" + brandid
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.ams_options = response.data.data;
          $this.$store.dispatch("generalLoader", {
            response: false
          });
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    async onSubmit(event,lead_index) {
      
      
      const $this = this;
      $this.disableSubmitBtn = true;
      $this.$v.$touch();

      if ($this.$v.$invalid) {
        $this.$store.dispatch("showtostrMessageNotifications", {
          message: "Please fill all the fields to proceed form!",
          type: "error"
        });
        $this.disableSubmitBtn = false;
        return;
      }

      const data = $this.$store.dispatch("postHttp", {
        url: "leads/convert-lead-to-client",
        formDetails: this.formData,
        formEvent: event.target
      });
      await data.then( (response) => {
        if (typeof response !== "undefined") {
          // console.log(response.data[0]);
          $this.$store.dispatch("showtostrMessageNotifications", {
            message: response.data.message,
            type: 'success'
          });
          this.$emit('updateLeadData', response.data[0]);
        }
      });
      $this.disableSubmitBtn = false;
    },
  }
};
</script>
<style lang="scss" scoped>

</style>